import { Button } from 'antd'
import { UserActions } from '../../../../entities/User'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import cls from './LogOutButton.module.scss'

export const LogOutButton = () => {
    const dispatch = useAppDispatch()

    const handleClickLogout = () => {
        dispatch(UserActions.logOut())
    }

    return (
        <Button
            className={classNames(cls.LogOutButton, {}, [])}
            onClick={handleClickLogout}
        >
            Выйти
        </Button>
    )
}
