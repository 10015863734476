import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';
import { fetchAllWorkdays } from '../../../Workday/model/services';
import { Availability } from '../types/AvailabilitySchema';

export const fetchAllAvailabilities = createAsyncThunk<
    Availability[],
    void,
    ThunkConfig<string>
>('availability/fetchAllAvailabilities', async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    try {
        const response = await extra.api.get<Availability[]>('/availability');
        return response.data;
    } catch (e: any) {
        return rejectWithValue('Failed to fetch availabilities');
    }
});



interface CreateAvailabilityDto {
    workdayId: number;
    startTime: string;
    endTime: string;
}

export const createAvailability = createAsyncThunk<
    Availability,
    CreateAvailabilityDto,
    ThunkConfig<string>
>('availability/createAvailability', async (data, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;
    try {
        const response = await extra.api.post<Availability>('/availability', data);
        dispatch(fetchAllWorkdays())
        return response.data;
    } catch (e: any) {
        handleAsyncError(e)
        return rejectWithValue('Failed to create availability');
    }
});


interface UpdateAvailabilityDto {
    id: number;
    data: Partial<{
        dayOfWeek: number;
        startTime: string;
        endTime: string;
    }>;
}

export const updateAvailability = createAsyncThunk<
    Availability,
    UpdateAvailabilityDto,
    ThunkConfig<string>
>('availability/updateAvailability', async ({ id, data }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    try {
        const response = await extra.api.patch<Availability>(`/availability/${id}`, data);
        return response.data;
    } catch (e: any) {
        handleAsyncError(e)
        return rejectWithValue('Failed to update availability');
    }
});




export const deleteAvailability = createAsyncThunk<
    void,
    number,
    ThunkConfig<string>
>('availability/deleteAvailability', async (id, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    try {
        await extra.api.delete(`/availability/${id}`);
    } catch (e: any) {
        handleAsyncError(e)
        return rejectWithValue('Failed to delete availability');
    }
});



export const fetchAvailabilityById = createAsyncThunk<
    Availability,
    number,
    ThunkConfig<string>
>('availability/fetchAvailabilityById', async (id, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    try {
        const response = await extra.api.get<Availability>(`/availability/${id}`);
        return response.data;
    } catch (e: any) {
        handleAsyncError(e)
        return rejectWithValue('Failed to fetch availability by ID');
    }
});


