import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    createUser,
    fetchAllUsers,
    fetchUserById,
    updateUser,
    deleteUser,
    fetchAllUsersPreferedMySchool,
} from '../services';
import { User, UsersSchema } from '../types/UsersSchema';

const initialState: UsersSchema = {
    users: [],
    currentUser: null,
    isLoading: false,
    error: null,
};

export const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Получение всех пользователей
            .addCase(fetchAllUsers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.isLoading = false;
                state.users = action.payload;
            })
            .addCase(fetchAllUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch users';
            })
            // Получение всех пользователей
            .addCase(fetchAllUsersPreferedMySchool.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllUsersPreferedMySchool.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.isLoading = false;
                state.users = action.payload;
            })
            .addCase(fetchAllUsersPreferedMySchool.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch users';
            })
            // Получение одного пользователя
            .addCase(fetchUserById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUserById.fulfilled, (state, action: PayloadAction<User>) => {
                state.isLoading = false;
                state.currentUser = action.payload;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch user';
            })
            // Создание пользователя
            .addCase(createUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
                state.isLoading = false;
                // state.users.push(action.payload);
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to create user';
            })
            // Обновление пользователя
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
                state.isLoading = false;
                const index = state.users.findIndex((user) => user.id === action.payload.id);
                if (index !== -1) {
                    state.users[index] = action.payload;
                }
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to update user';
            })
            // Удаление пользователя
            .addCase(deleteUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.users = state.users.filter((user) => user.id !== action.meta.arg);
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to delete user';
            });
    },
});

export const { actions: UsersActions } = UsersSlice;
export const { reducer: UsersReducer } = UsersSlice;
