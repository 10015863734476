import { Popconfirm, Tag } from 'antd'
import { Button } from 'antd-mobile'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSocketContext } from '../../../../app/providers/WebSocketContext/WebSocketContext'
import { getUserData } from '../../../../entities/User'
import { AvailabilityStatusEnum } from '../../../Availability/model/types/AvailabilitySchema'
import { EventType } from '../../../Students/model/types/CalendarTypes'

interface Props {
    event: EventType
}
export const CreateAppointmentButton = ({ event }: Props) => {
    const { user } = useSelector(getUserData)
    const { socket } = useSocketContext()

    const { t } = useTranslation()
    const { availability, instructor } = event
    const appointment = availability.appointment
    const isOwnedByCurrentUser = appointment?.student.userId === user?.id

    const handleClickCreateAppointment = () => {
        if (!socket) return
        socket.emit('createAppointment', {
            dto: {
                availabilityId: availability.id,
                notes: ''
            },
            userId: user?.id,
            instructorUserId: event.instructor.user.id,
            workdayId: event.workday.id
        })
    }

    const handleClickDeleteAppointment = () => {
        if (!socket) return
        socket.emit('deleteAppointment', {
            appointmentId: appointment?.id,
            instructorUserId: event.instructor.user.id,
            workdayId: event.workday.id,
            userId: user?.id
        })
    }

    const isTimeExpired = dayjs().isAfter(
        dayjs(`${event.start}`, 'YYYY-MM-DD HH:mm')
    )

    const isTimeExpiredToCancel = (
        dateISO: Date | undefined,
        startTime: string | undefined
    ): boolean => {
        // Извлекаем только дату из ISO строки и объединяем с `startTime`
        const eventDate = dayjs(dateISO).format('YYYY-MM-DD') // Пример: "2024-11-29"
        const eventStartDateTime = dayjs(
            `${eventDate} ${startTime}`,
            'YYYY-MM-DD HH:mm'
        )
        const currentTime = dayjs() // Текущее время

        // Проверяем, осталось ли меньше 24 часов
        return currentTime.isAfter(eventStartDateTime.subtract(24, 'hour'))
    }

    if (isOwnedByCurrentUser) {
        return availability.status === AvailabilityStatusEnum.BOOKED ? (
            <Popconfirm
                title={`Отказаться от записи на ${availability.startTime}`}
                onConfirm={handleClickDeleteAppointment}
                disabled={isTimeExpired}
            >
                <Button
                    size="small"
                    disabled={isTimeExpired}
                    color={
                        isTimeExpired ||
                        isTimeExpiredToCancel(
                            appointment?.date,
                            appointment?.startTime
                        )
                            ? 'success'
                            : 'danger'
                    }
                >
                    {isTimeExpired
                        ? 'Занятие прошло'
                        : isTimeExpiredToCancel(
                              appointment?.date,
                              appointment?.startTime
                          )
                        ? 'Вы записаны'
                        : 'Отказаться от записи'}
                </Button>
            </Popconfirm>
        ) : (
            <Popconfirm
                title={`Записаться к ${instructor.user.firstName} ${instructor.user.lastName} на ${availability.startTime}`}
                onConfirm={handleClickCreateAppointment}
                disabled={isTimeExpired}
            >
                <Button size="small" disabled={isTimeExpired}>
                    {isTimeExpired ? 'Время истекло' : 'Записаться'}
                </Button>
            </Popconfirm>
        )
    }

    return availability.status === AvailabilityStatusEnum.AVAILABLE ? (
        <Popconfirm
            title={`Записаться к ${instructor.user.firstName} ${instructor.user.lastName} на ${availability.startTime}`}
            onConfirm={handleClickCreateAppointment}
            disabled={isTimeExpired}
        >
            <Button size="small" disabled={isTimeExpired} shape="rounded">
                {isTimeExpired ? 'Время истекло' : 'Записаться'}
            </Button>
        </Popconfirm>
    ) : (
        <Button size="small" disabled shape="rounded">
            {availability.status === AvailabilityStatusEnum.BOOKED
                ? 'Занято'
                : 'Недоступно'}
             <Tag color="red">{t(availability.status)}</Tag>
        </Button>
    )
}
