import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserSchema } from '../types/UserSchema';

import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage';

const initialState: UserSchema = {
    isAuth: false,
    _inited: false
};

export const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<User>) => {
            state.user = action.payload
            state.isAuth = true
        },
        initAuth: (state) => {
            state._inited = true
        },
        logOut: (state) => {
            state.isAuth = false
            state.user = undefined
            localStorage.removeItem(USER_TOKEN_KEY)
            localStorage.removeItem(USER_REFRESH_TOKEN_KEY)
        },
        setConnectedToSchool: (state, action: PayloadAction<boolean>) => {
            state.connectedToSchool = action.payload
        },
    },
});

export const { actions: UserActions } = UserSlice;
export const { reducer: UserReducer } = UserSlice;