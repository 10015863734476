import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    createSchool,
    fetchAllSchools,
    fetchSchoolById,
    updateSchool,
    deleteSchool,
} from '../services';
import { School, SchoolSchema } from '../types/SchoolsSchema';

const initialState: SchoolSchema = {
    schools: [],
    currentSchool: null,
    isLoading: false,
    error: null,
};

export const SchoolsSlice = createSlice({
    name: 'schools',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Получение всех школ
            .addCase(fetchAllSchools.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllSchools.fulfilled, (state, action: PayloadAction<School[]>) => {
                state.isLoading = false;
                state.schools = action.payload;
            })
            .addCase(fetchAllSchools.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch schools';
            })
            // Получение школы по ID
            .addCase(fetchSchoolById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSchoolById.fulfilled, (state, action: PayloadAction<School>) => {
                state.isLoading = false;
                state.currentSchool = action.payload;
            })
            .addCase(fetchSchoolById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to fetch school';
            })
            // Создание школы
            .addCase(createSchool.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createSchool.fulfilled, (state, action: PayloadAction<School>) => {
                state.isLoading = false;
                state.schools.push(action.payload);
            })
            .addCase(createSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to create school';
            })
            // Обновление школы
            .addCase(updateSchool.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateSchool.fulfilled, (state, action: PayloadAction<School>) => {
                state.isLoading = false;
                const index = state.schools.findIndex((school) => school.id === action.payload.id);
                if (index !== -1) {
                    state.schools[index] = action.payload;
                }
            })
            .addCase(updateSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to update school';
            })
            // Удаление школы
            .addCase(deleteSchool.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSchool.fulfilled, (state, action) => {
                state.isLoading = false;
                state.schools = state.schools.filter((school) => school.id !== action.meta.arg);
            })
            .addCase(deleteSchool.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || 'Failed to delete school';
            });
    },
});

export const { actions: SchoolsActions } = SchoolsSlice;
export const { reducer: SchoolsReducer } = SchoolsSlice;
