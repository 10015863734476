import { postRequestError } from '../const/text'
import { message } from "antd"

export function handleAsyncError(error: any): any {
    // Проверяем, если ошибка уже содержит нужное сообщение
    if (error.response && error.response.data && error.response.data.message) {
        return message.error(error.response.data.message)
    }

    // Если это ошибка типа Error
    if (error instanceof Error) {
        return message.error(error.message)
    }

    // Любые другие непредвиденные ошибки
    return message.error(postRequestError)

}
