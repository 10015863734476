import { Card, Tag } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getUserIsAuth } from '../../../../entities/User'
import {
    getUserData,
    getUserRole
} from '../../../../entities/User/model/selectors/getUserdata'
import { LogOutButton } from '../../../../features/AuthByUsername/ui/LogOutButton/LogOutButton'
import { getRouteLoginPage } from '../../../../shared/lib/const/router'
import { UserRoles } from '../../../../shared/lib/globalTypes/tenderSearch'

interface RequireAuthProps {
    children: JSX.Element
    roles?: any[]
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
    const isAuth = useSelector(getUserIsAuth)
    const userRole = useSelector(getUserRole)
    const { connectedToSchool } = useSelector(getUserData)

    const hasRequiredRoles = useMemo(() => {
        if (!roles) {
            return true
        }
        return roles.includes(userRole)
    }, [roles, userRole])

    if (!hasRequiredRoles) {
        return <Navigate to={getRouteLoginPage()} replace />
    }

    if (!isAuth) {
        return <div>not found</div>
    }
    if (userRole === UserRoles.INSTRUCTOR || userRole === UserRoles.STUDENT) {
        if (!connectedToSchool) {
            return (
                <Card>
                    <Tag color="red">
                        Вы не прикреплены к школе, обратитесь к администратору
                        школы
                    </Tag>
                    <LogOutButton />
                </Card>
            )
        }
    }

    return children
}
