import { Card, List, Tag, Typography } from 'antd'
import { SwipeAction, SwipeActionRef } from 'antd-mobile'
import React, { useRef } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { UserAvatarCircle } from '../../../../shared/ui/UserAvatarCircle/UserAvatarCircle'
import {
    Appointment,
    AppointmentStatusesEnum
} from '../../model/types/AppointmentsSchema'

const { Text } = Typography

interface StudentScheduleProps {
    records?: Appointment[]
    handleCancelAppointment: (id: number) => void
}

const InstructorSchedule: React.FC<StudentScheduleProps> = ({
    records,
    handleCancelAppointment
}) => {
    const { t } = useTranslation()
    const ref = useRef<SwipeActionRef>(null)
    if (!records) {
        return null
    }

    const formatDateToLocalWithTime = (
        date: string | Date,
        startTime: string
    ): Date => {
        // Преобразуем строку в объект Date, если это необходимо
        const utcDate = typeof date === 'string' ? new Date(date) : date

        // Переводим UTC-время в локальное время GMT+6
        const localDate = new Date(utcDate.getTime() + 6 * 60 * 60 * 1000)

        // Извлекаем день, месяц и год
        const [year, month, day] = [
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate()
        ]

        // Извлекаем часы и минуты из startTime
        const [hours, minutes] = startTime.split(':').map(Number)

        // Создаём новую дату с комбинированным временем
        return new Date(year, month, day, hours, minutes, 0)
    }
    // Функция для отображения обратного отсчёта
    const renderCountdown = (date: string | Date, startTime: string) => {
        const targetDate = formatDateToLocalWithTime(date, startTime).getTime()

        return (
            <Countdown
                date={targetDate}
                renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                        return <Tag color="blue"> Занятие идет</Tag>
                    }
                    return (
                        <Text>
                            {days !== 0 && `${days}д `}
                            {hours !== 0 && `${hours}ч `}
                            {minutes !== 0 && `${minutes}м `}
                            {seconds !== 0 && `${seconds}с`}
                        </Text>
                    )
                }}
            />
        )
    }

    return (
        <Card
            size="small"
            title="Ваши записи"
            bordered={false}
            style={{ maxWidth: 800, margin: 'auto', width: '100%' }}
        >
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={records}
                locale={{ emptyText: 'Нет записей' }}
                renderItem={(record) => (
                    <SwipeAction
                        style={{ marginTop: '-1px' }}
                        ref={ref}
                        closeOnAction={false}
                        closeOnTouchOutside={false}
                        rightActions={
                            record.status === 'BOOKED'
                                ? [
                                      {
                                          key: 'delete',
                                          text: 'Отказаться',
                                          color: 'danger',
                                          onClick: () =>
                                              handleCancelAppointment(record.id)
                                      }
                                  ]
                                : []
                        }
                    >
                        <Card size="small">
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <UserAvatarCircle
                                            url={record.student.user.photo}
                                        />
                                    }
                                    title={
                                        <>
                                            {record.student.user.firstName}{' '}
                                            {record.student.user.lastName}
                                        </>
                                    }
                                    description={
                                        <>
                                            <Text strong>Дата:</Text>{' '}
                                            {new Date(
                                                record.date
                                            ).toLocaleDateString('ru-RU')}{' '}
                                            <Tag color="green">
                                                {' '}
                                                {record.startTime} -{' '}
                                                {record.endTime}
                                            </Tag>
                                            <br />
                                            <Text strong>Телефон:</Text>{' '}
                                            <a
                                                href={`tel:${record.student.user.phone}`}
                                            >
                                                {record.student.user.phone}
                                            </a>{' '}
                                            <br />
                                            <Text strong>Статус:</Text>{' '}
                                            {t(record.status)}
                                            <br />
                                            {record.status ===
                                                AppointmentStatusesEnum.BOOKED && (
                                                <>
                                                    <Text strong>
                                                        До занятия:
                                                    </Text>{' '}
                                                    {renderCountdown(
                                                        new Date(record.date),
                                                        record.startTime
                                                    )}
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </List.Item>
                        </Card>
                    </SwipeAction>
                )}
            />
        </Card>
    )
}

export default InstructorSchedule
