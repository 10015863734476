import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailabilitySchema } from '../types/AvailabilitySchema';
import { createAvailability, deleteAvailability, fetchAllAvailabilities, updateAvailability } from '../services';

const initialState: AvailabilitySchema = {
    availabilities: [],
    isLoading: false,
    error: null,
};

export const AvailabilitySlice = createSlice({
    name: 'Availability',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch All
            .addCase(fetchAllAvailabilities.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllAvailabilities.fulfilled, (state, action) => {
                state.isLoading = false;
                state.availabilities = action.payload;
            })
            .addCase(fetchAllAvailabilities.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })

            // Create
            .addCase(createAvailability.fulfilled, (state, action) => {
                state.availabilities.push(action.payload);
            })

            // Update
            .addCase(updateAvailability.fulfilled, (state, action) => {
                const index = state.availabilities.findIndex(
                    (item) => item.id === action.payload.id
                );
                if (index !== -1) {
                    state.availabilities[index] = action.payload;
                }
            })

            // Delete
            .addCase(deleteAvailability.fulfilled, (state, action) => {
                state.availabilities = state.availabilities.filter(
                    (item) => item.id !== action.meta.arg
                );
            });
    },
});

export const { actions: AvailabilityActions } = AvailabilitySlice;
export const { reducer: AvailabilityReducer } = AvailabilitySlice;