import { List, Tag, Typography } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRouteInstructorDetailPage } from '../../../../shared/lib/const/router'
import { useAppDispatch } from '../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { getInstructorsdata } from '../../model/selectors/getInstructorsdata'
import { fetchAllInstructorsBySchoolId } from '../../model/services'
import { InstructorCard } from '../InstructorCard/InstructorCard'
import cls from './SchoolInstructors.module.scss'

const { Title } = Typography

export const SchoolInstructors = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleNavigate = (instructorId: number) => {
        navigate(getRouteInstructorDetailPage(instructorId))
    }
    const { error, instructors, isLoading } = useSelector(getInstructorsdata)

    useEffect(() => {
        dispatch(fetchAllInstructorsBySchoolId())
    }, [dispatch])

    if (isLoading) {
        return <Loader />
    }
    if (error) {
        return <Tag color="red">Ошибка при получении списка инструкторов</Tag>
    }

    return (
        <div className={cls.instructorListPage}>
            <Title level={5} className={cls.title}>
                Инструкторы:
            </Title>
            <List
                size="small"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 6
                }}
                dataSource={instructors}
                renderItem={(instructor) => (
                    <List.Item>
                        <InstructorCard
                            handleNavigate={handleNavigate}
                            instructor={instructor}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}
