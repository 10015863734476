import { createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../../../shared/api/api';
import { CreateSchoolDto, School, UpdateSchoolDto } from '../types/SchoolsSchema';

// Создание школы
export const createSchool = createAsyncThunk<
    School,
    CreateSchoolDto,
    { rejectValue: string }
>('schools/createSchool', async (schoolData, { rejectWithValue }) => {
    try {
        const response = await $api.post<School>('/school', schoolData);
        return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response?.data?.message || 'Failed to create school');
    }
});

// Получение всех школ
export const fetchAllSchools = createAsyncThunk<
    School[],
    void,
    { rejectValue: string }
>('schools/fetchAllSchools', async (_, { rejectWithValue }) => {
    try {
        const response = await $api.get<School[]>('/school');
        return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response?.data?.message || 'Failed to fetch schools');
    }
});

// Получение школы по ID
export const fetchSchoolById = createAsyncThunk<
    School,
    number,
    { rejectValue: string }
>('schools/fetchSchoolById', async (id, { rejectWithValue }) => {
    try {
        const response = await $api.get<School>(`/school/${id}`);
        return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response?.data?.message || 'Failed to fetch school');
    }
});

// Обновление школы
export const updateSchool = createAsyncThunk<
    School,
    { id: number; data: UpdateSchoolDto },
    { rejectValue: string }
>('schools/updateSchool', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await $api.patch<School>(`/school/${id}`, data);
        return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response?.data?.message || 'Failed to update school');
    }
});

// Удаление школы
export const deleteSchool = createAsyncThunk<
    void,
    number,
    { rejectValue: string }
>('schools/deleteSchool', async (id, { rejectWithValue }) => {
    try {
        await $api.delete(`/school/${id}`);
    } catch (e: any) {
        return rejectWithValue(e.response?.data?.message || 'Failed to delete school');
    }
});
