import { Button, PullToRefresh } from 'antd-mobile'
import { sleep } from 'antd-mobile/es/utils/sleep'
import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchAppointmentsStudent } from '../../../features/Appointments'
import {
    getAppointmentsdata,
    getAppointmentsdataForStudentSchedule
} from '../../../features/Appointments/model/selectors/getAppointmentsdata'
import { deleteAppointment } from '../../../features/Appointments/model/services'
import { AppointmentStatusesEnum } from '../../../features/Appointments/model/types/AppointmentsSchema'
import StudentSchedule from '../../../features/Appointments/ui/StudentsSchedule/StudentsSchedule'
import { classNames } from '../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { HStack, VStack } from '../../../shared/ui/Stack'
import cls from './MyAppointments.module.scss'

interface MyAppointmentsProps {
    className?: string
}

const MyAppointments = memo((props: MyAppointmentsProps) => {
    const { className } = props
    const dispatch = useAppDispatch()

    const data = useSelector(getAppointmentsdataForStudentSchedule)
    const { isLoading } = useSelector(getAppointmentsdata)
    const [selectedStatus, setStatus] = useState<AppointmentStatusesEnum>(
        AppointmentStatusesEnum.BOOKED
    )

    const handleFetchData = useCallback(
        (status: AppointmentStatusesEnum) => {
            dispatch(fetchAppointmentsStudent(status))
        },
        [dispatch]
    )

    const handleCancelAppointment = (appointmentId: number) => {
        dispatch(
            deleteAppointment({
                appointmentId: appointmentId,
                callback: () => handleFetchData(selectedStatus)
            })
        )
    }

    const handleClickStatus = useCallback(
        (status: AppointmentStatusesEnum) => {
            handleFetchData(status)
            return setStatus(status)
        },
        [handleFetchData]
    )

    useEffect(() => {
        handleFetchData(selectedStatus)
        //eslint-disable-next-line
    }, [])

    return (
        <div className={classNames(cls.MyAppointments, {}, [className])}>
            <PullToRefresh
                pullingText="тяните вниз, для обновления"
                canReleaseText="отпустите..."
                refreshingText="Загрузка..."
                completeText="Завершено"
                onRefresh={async () => {
                    await sleep(1000)
                    handleFetchData(selectedStatus)
                }}
            >
                <VStack max align="center" gap="8">
                    <HStack max gap="4">
                        <Button
                            size="small"
                            color={
                                selectedStatus ===
                                AppointmentStatusesEnum.BOOKED
                                    ? 'primary'
                                    : 'default'
                            }
                            loading={isLoading}
                            onClick={() =>
                                handleClickStatus(
                                    AppointmentStatusesEnum.BOOKED
                                )
                            }
                        >
                            Записи
                        </Button>
                        <Button
                            size="small"
                            color={
                                selectedStatus ===
                                AppointmentStatusesEnum.COMPLETED
                                    ? 'primary'
                                    : 'default'
                            }
                            loading={isLoading}
                            onClick={() =>
                                handleClickStatus(
                                    AppointmentStatusesEnum.COMPLETED
                                )
                            }
                        >
                            Архив
                        </Button>
                    </HStack>
                    <StudentSchedule
                        records={data}
                        handleCancelAppointment={handleCancelAppointment}
                    />
                </VStack>
            </PullToRefresh>
        </div>
    )
})

export default MyAppointments
