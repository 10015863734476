import { RouteProps } from 'react-router-dom'
import { AdminPageLayout } from '../../../pages/AdminPageLayout'
import { AvailabilitesPage } from '../../../pages/forInstructors/AvailabilitesPage'
import { AvailabilitiDetailsPage } from '../../../pages/forInstructors/AvailabilitiDetailsPage'
import InstructorDetailPage from '../../../pages/forStudents/InstructorDetailPage/ui/InstructorDetailPage/InstructorDetailPage'
import { InstructorPageLayout } from '../../../pages/InstructorPageLayout'
import { InstructorsListBySchoolPage } from '../../../pages/forStudents/InstructorsListBySchoolPage'
import { InstructorsPage } from '../../../pages/forSchoolAdmin/InstructorsPage'
import { LoginPage } from '../../../pages/generalPages/LoginPage'
import { SchoolAdminPageLayout } from '../../../pages/SchoolAdminPageLayout'
import { StudentPageLayout } from '../../../pages/StudentPageLayout'
import { StudentsPage } from '../../../pages/forSchoolAdmin/StudentsPage'
import { UsersPage } from '../../../pages/forSchoolAdmin/UsersPage'
import {
    ADMIN_PAGE_ROUTE,
    AppRoutes,
    ChildAppRoutes,
    getRouteAdminPageLayout,
    getRouteAvailibliesPage,
    getRouteAvailiblitesDetailsPage,
    getRouteInstructorDetailPage,
    getRouteInstructorsListBySchool,
    getRouteInstructorsPage,
    getRouteLoginPage,
    getRouteMyappointmentsInstructorPage,
    getRouteMyAppointmentsStudent,
    getRouteNotFoundPage,
    getRouteStudentsPage,
    getRouteUserRegisterPage,
    getRouteUsersPage,
    INSTRUCTOR_PAGE_ROUTE,
    SCHOOL_ADMIN_PAGE_ROUTE,
    STUDENT_PAGE_ROUTE
} from '../../lib/const/router'
import { UserRoles } from '../../lib/globalTypes/tenderSearch'
import { UserRegisterPage } from '../../../pages/generalPages/UserRegisterPage'
import { NotFoundPage } from '../../../pages/generalPages/NotFoundPage'
import MyAppointments from '../../../pages/forStudents/MyAppointments/MyAppointments'
import { MyAppointmentsInstructorPageAsync } from '../../../pages/forInstructors/MyAppointmentsInstructorPage/MyAppointmentsInstructorPage.async'

export type AppRoutesProps = RouteProps & {
    authOnly?: boolean
    child?: Record<string | ChildAppRoutes, AppRoutesProps>
    roles?: UserRoles[]
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.ADMIN]: {
        path: ADMIN_PAGE_ROUTE,
        element: <AdminPageLayout />,
        authOnly: true,
        roles: [UserRoles.SUPER_ADMIN],
        child: {
            [ChildAppRoutes.ADMIN_PANEL_PAGE]: {
                path: getRouteAdminPageLayout(),
                element: <div />
            }
        }
    },
    [AppRoutes.SCHOOL_ADMIN]: {
        path: SCHOOL_ADMIN_PAGE_ROUTE,
        element: <SchoolAdminPageLayout />,
        authOnly: true,
        roles: [UserRoles.SCHOOL_ADMIN],
        child: {
            [ChildAppRoutes.STUDENTS_PAGE]: {
                path: getRouteStudentsPage(),
                element: <StudentsPage />
            },
            [ChildAppRoutes.INSTRUCTORS_PAGE]: {
                path: getRouteInstructorsPage(),
                element: <InstructorsPage />
            },
            [ChildAppRoutes.USERS_PAGE]: {
                path: getRouteUsersPage(),
                element: <UsersPage />
            }
        }
    },
    [AppRoutes.INSTRUCTOR]: {
        path: INSTRUCTOR_PAGE_ROUTE,
        element: <InstructorPageLayout />,
        authOnly: true,
        roles: [UserRoles.INSTRUCTOR],
        child: {
            [ChildAppRoutes.INSTRUCTOR_AVAILIBILITES_PAGE]: {
                path: getRouteAvailibliesPage(),
                element: <AvailabilitesPage />
            },
            [ChildAppRoutes.INSTRUCTOR_AVAILIBILITES_DETAILS_PAGE]: {
                path: getRouteAvailiblitesDetailsPage(':workDayId'),
                element: <AvailabilitiDetailsPage />
            },
            [ChildAppRoutes.INSTRUCTOR_MY_APPOINTMENTS_PAGE]: {
                path: getRouteMyappointmentsInstructorPage(),
                element: <MyAppointmentsInstructorPageAsync />
            }
        }
    },
    [AppRoutes.STUDENT]: {
        path: STUDENT_PAGE_ROUTE,
        element: <StudentPageLayout />,
        authOnly: true,
        roles: [UserRoles.STUDENT],
        child: {
            [ChildAppRoutes.STUDENT_AVAILIBILITES_PAGE]: {
                path: getRouteInstructorsListBySchool(),
                element: <InstructorsListBySchoolPage />
            },
            [ChildAppRoutes.STUDENT_INSTRUCTOR_DETAIL_PAGE]: {
                path: getRouteInstructorDetailPage(':instructorId'),
                element: <InstructorDetailPage />
            },
            [ChildAppRoutes.STUDENT_MY_APPOINTMENTS_PAGE]: {
                path: getRouteMyAppointmentsStudent(),
                element: <MyAppointments />
            }
        }
    },
    [AppRoutes.LOGIN]: {
        path: getRouteLoginPage(),
        element: <LoginPage />,
        authOnly: false
    },
    [AppRoutes.USER_REGISTER]: {
        path: getRouteUserRegisterPage(),
        element: <UserRegisterPage />,
        authOnly: false
    },
    [AppRoutes.NOT_FOUND]: {
        path: getRouteNotFoundPage(),
        element: <NotFoundPage />,
        authOnly: false
    },
    [AppRoutes.ALL]: {
        path: '*',
        element: <LoginPage />,
        authOnly: false
    }
}
