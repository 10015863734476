
export enum AppRoutes {
    ADMIN = 'ADMIN',
    SCHOOL_ADMIN = 'SCHOOL_ADMIN',
    LOGIN = 'LOGIN_PAGE',
    NOT_FOUND = 'NOT_FOUND',
    ALL = 'ALL',
    USER_REGISTER = 'USER_REGISTER',
    INSTRUCTOR = 'INSTRUCTOR',
    STUDENT = 'STUDENT',

}

export enum ChildAppRoutes {
    //for _admin
    ADMIN_PANEL_PAGE = 'ADMIN_PANEL_PAGE',

    //for school_admin
    STUDENTS_PAGE = 'STUDENTS_PAGE',
    INSTRUCTORS_PAGE = 'INSTRUCTORS_PAGE',
    USERS_PAGE = 'USERS_PAGE',

    // for instructors
    INSTRUCTOR_AVAILIBILITES_PAGE = "INSTRUCTOR_AVAILIBILITES_PAGE",
    INSTRUCTOR_AVAILIBILITES_DETAILS_PAGE = "INSTRUCTOR_AVAILIBILITES_DETAILS_PAGE",
    INSTRUCTOR_MY_APPOINTMENTS_PAGE = "INSTRUCTOR_MY_APPOINTMENTS_PAGE",

    // for students

    STUDENT_AVAILIBILITES_PAGE = "STUDENT_AVAILIBILITES_PAGE",
    STUDENT_APPOINTMENTS_PAGE = "STUDENT_APPOINTMENTS_PAGE",
    STUDENT_INSTRUCTOR_DETAIL_PAGE = "STUDENT_INSTRUCTOR_DETAIL_PAGE",
    STUDENT_MY_APPOINTMENTS_PAGE = "STUDENT_MY_APPOINTMENTS_PAGE",

}

export const ADMIN_PAGE_ROUTE = '/admin'
export const getRouteAdminPageLayout = () => ADMIN_PAGE_ROUTE


export const SCHOOL_ADMIN_PAGE_ROUTE = '/school_admin'
export const getRouteStudentsPage = () => `${SCHOOL_ADMIN_PAGE_ROUTE}/students`
export const getRouteInstructorsPage = () => `${SCHOOL_ADMIN_PAGE_ROUTE}/instructors`
export const getRouteUsersPage = () => `${SCHOOL_ADMIN_PAGE_ROUTE}/users`

export const INSTRUCTOR_PAGE_ROUTE = '/instructor'
export const getRouteAvailibliesPage = () => `${INSTRUCTOR_PAGE_ROUTE}/availibilites`
export const getRouteAvailiblitesDetailsPage = (workdDayId: string) => `${INSTRUCTOR_PAGE_ROUTE}/availibilites/${workdDayId}`
export const getRouteAppointmentsPage = () => `${INSTRUCTOR_PAGE_ROUTE}/appointments`
export const getRouteMyappointmentsInstructorPage = () => `${INSTRUCTOR_PAGE_ROUTE}/appointments`


export const STUDENT_PAGE_ROUTE = '/student'
export const getRouteInstructorsListBySchool = () => `${STUDENT_PAGE_ROUTE}/instructors`
export const getRouteInstructorDetailPage = (instructorId: string | number) => `${STUDENT_PAGE_ROUTE}/instructors/${instructorId}`
export const getRouteMyAppointmentsStudent = () => `${STUDENT_PAGE_ROUTE}/appointments`


// general

export const getRouteLoginPage = () => '/login'
export const getRouteNotFoundPage = () => '/notfound'
export const getRouteUserRegisterPage = () => '/register'
