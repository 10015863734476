import { message } from 'antd'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { io, Socket } from 'socket.io-client'
import { getUserIsAuth } from '../../../entities/User'
import {
    getUserId,
    getUserRole
} from '../../../entities/User/model/selectors/getUserdata'
import { AppointmentsActions } from '../../../features/Appointments/model/slices/AppointmentsSlice'
import { Appointment } from '../../../features/Appointments/model/types/AppointmentsSchema'
import { InstructorsActions } from '../../../features/Instructors/model/slices/InstructorsSlice'
import { InstructorWorkdaysType } from '../../../features/Instructors/model/types/instructorWorkdaysType'
import { WorkdayActions } from '../../../features/Workday/model/slices/WorkdaySlice'
import { Workday } from '../../../features/Workday/model/types/WorkdaySchema'
import { USER_TOKEN_KEY } from '../../../shared/lib/const/localstorage'
import { UserRoles } from '../../../shared/lib/globalTypes/tenderSearch'
import { useAppDispatch } from '../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import { defaultWebsocketUrl } from '../../../shared/api/api'

interface SocketContextValue {
    socket: Socket | null
    isAuth: boolean
}

const SocketContext = createContext<SocketContextValue>({
    socket: null,
    isAuth: false
})

interface SocketProviderProps {
    children?: React.ReactNode
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const isAuth = useSelector(getUserIsAuth)
    const token = localStorage.getItem(USER_TOKEN_KEY)
    const SERVER_URL = defaultWebsocketUrl
    const userId = useSelector(getUserId)
    const dispatch = useAppDispatch()
    const userRole = useSelector(getUserRole)

    const [socket, setSocket] = useState<Socket | null>(null)

    const newSocket = io(SERVER_URL, {
        transports: ['websocket'],
        auth: {
            token: `Bearer ${token}`
        },
        reconnection: true, // Включить автоматическое переподключение (по умолчанию true)
        reconnectionAttempts: 5, // Количество попыток переподключения (по умолчанию Infinity)
        reconnectionDelay: 1000, // Задержка перед первой попыткой (по умолчанию 1000 мс)
        reconnectionDelayMax: 5000, // Максимальная задержка между попытками (по умолчанию 5000 мс)
        randomizationFactor: 0.5 // Фактор случайности (по умолчанию 0.5)
    })

    useEffect(() => {
        if (!isAuth) {
            // Если пользователь не авторизован, не подключаемся к сокету
            setSocket(null)
            return
        }

        newSocket.on('connect', () => {
            console.log('Подключено к серверу. Socket ID:', newSocket.id)
            // Присоединяемся к комнате школы
            if (userId) {
                newSocket.emit('joinRoom', userId)
            }
        })

        newSocket.on('joinedRoom', (message: string) => {
            console.log(message)
        })

        // for instructors
        newSocket.on('availabilityCreated', (workday: Workday) => {
            if (userRole === UserRoles.INSTRUCTOR) {
                console.log('Создана новая доступность:', workday)
                dispatch(WorkdayActions.setWorkdayDetailFromWebSocket(workday))
            }
        })

        newSocket.on('availabilityDeleted', (workday: Workday) => {
            if (userRole === UserRoles.INSTRUCTOR) {
                console.log('Удалена доступность:', workday)
                dispatch(WorkdayActions.setWorkdayDetailFromWebSocket(workday))
            }
        })

        newSocket.on('appointmentCreated', (workday: Workday) => {
            if (userRole === UserRoles.INSTRUCTOR) {
                console.log('Новая доступность:', workday)
                dispatch(WorkdayActions.setWorkdayDetailFromWebSocket(workday))
            }
        })

        newSocket.on('notificationForInstructor', (text: string) => {
            if (userRole === UserRoles.INSTRUCTOR) {
                message.info(text, 10)
            }
        })

        newSocket.on(
            'myAppointmentsInstructor',
            (appointments: Appointment[]) => {
                if (userRole === UserRoles.INSTRUCTOR) {
                    dispatch(
                        AppointmentsActions.setUpdatedAppointmentsWebSocket(
                            appointments
                        )
                    )
                }
            }
        )

        // for students
        newSocket.on(
            'updatedWorkdays',
            (workdays: InstructorWorkdaysType[]) => {
                console.log('updated work days')
                if (userRole === UserRoles.STUDENT) {
                    console.log('Новый workd days:', workdays)
                    dispatch(
                        InstructorsActions.setUpdatedWorkdaysWebSocket(workdays)
                    )
                }
            }
        )
        newSocket.on('myAppointmentsStudent', (appointments: Appointment[]) => {
            if (userRole === UserRoles.STUDENT) {
                dispatch(
                    AppointmentsActions.setUpdatedAppointmentsWebSocket(
                        appointments
                    )
                )
            }
        })

        newSocket.on('notificationForStudent', (text: string) => {
            if (userRole === UserRoles.STUDENT) {
                message.info(text, 10)
            }
        })

        // errors

        newSocket.on('error', (error: { message: string }) => {
            message.error(`Ошибка от сервера: ${error.message}`)
            console.error()
        })

        setSocket(newSocket)

        return () => {
            newSocket.disconnect()
        }
        //eslint-disable-next-line
    }, [isAuth, userId, token, userRole, dispatch])

    return (
        <SocketContext.Provider value={{ socket, isAuth }}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocketContext = () => useContext(SocketContext)
