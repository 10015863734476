import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentsSchema, Student } from '../types/StudentsSchema';
import { createStudent, fetchAllStudents, fetchStudentById, updateStudent, deleteStudent } from '../services';

const initialState: StudentsSchema = {
    students: [],
    student: null,
    isLoading: false,
    error: null,
};

export const StudentsSlice = createSlice({
    name: 'Students',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Создание студента
        builder.addCase(createStudent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(createStudent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.students = action.payload
        });
        builder.addCase(createStudent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });

        // Получение всех студентов
        builder.addCase(fetchAllStudents.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchAllStudents.fulfilled, (state, action: PayloadAction<Student[]>) => {
            state.isLoading = false;
            state.students = action.payload;
        });
        builder.addCase(fetchAllStudents.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });

        // Получение одного студента
        builder.addCase(fetchStudentById.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchStudentById.fulfilled, (state, action: PayloadAction<Student>) => {
            state.isLoading = false;
            state.student = action.payload;
        });
        builder.addCase(fetchStudentById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });

        // Обновление студента
        builder.addCase(updateStudent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(updateStudent.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateStudent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });

        // Удаление студента
        builder.addCase(deleteStudent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(deleteStudent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.students = action.payload
        });
        builder.addCase(deleteStudent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });
    },
});

export const { actions: StudentsActions } = StudentsSlice;
export const { reducer: StudentsReducer } = StudentsSlice;
