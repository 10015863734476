import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { CreateInstructorDto, Instructor } from '../types/InstructorsSchema';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';
import { fetchAllUsers } from '../../../Users/model/services';
import { InstructorWorkdaysType } from '../types/instructorWorkdaysType';

// Fetch all instructors
export const fetchAllInstructors = createAsyncThunk<Instructor[], void, ThunkConfig<string>>(
    'instructors/fetchAll',
    async (_, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Instructor[]>('/instructor');
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to fetch instructors');
        }
    }
);


// Fetch all instructors by schoolId
export const fetchAllInstructorsBySchoolId = createAsyncThunk<Instructor[], void, ThunkConfig<string>>(
    'instructors/fetchAllInstructorsBySchoolId',
    async (_, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Instructor[]>('/instructor/school');
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to fetch instructors');
        }
    }
);



// Fetch instructor by ID
export const fetchInstructorById = createAsyncThunk<Instructor, number, ThunkConfig<string>>(
    'instructors/fetchById',
    async (id, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Instructor>(`/instructor/${id}`);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to fetch instructor');
        }
    }
);

export const fetchInstructorWorkdaysFromToday = createAsyncThunk<InstructorWorkdaysType[], number, ThunkConfig<string>>(
    'instructors/fetchInstructorWorkdaysFromToday',
    async (id, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<InstructorWorkdaysType[]>(`/instructor/${id}/workdays`);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to fetch instructor');
        }
    }
);

// Create instructor
export const createInstructor = createAsyncThunk<Instructor, { userId: number }, ThunkConfig<string>>(
    'instructors/create',
    async (data, thunkAPI) => {
        const { extra, dispatch, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.post<Instructor>('/instructor', data);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to create instructor');
        } finally {
            dispatch(fetchAllUsers())
        }
    }
);

// Update instructor
export const updateInstructor = createAsyncThunk<Instructor, { id: number; data: Partial<CreateInstructorDto> }, ThunkConfig<string>>(
    'instructors/update',
    async ({ id, data }, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.patch<Instructor>(`/instructor/${id}`, data);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to update instructor');
        }
    }
);

// Delete instructor
export const deleteInstructor = createAsyncThunk<Instructor[], number, ThunkConfig<string>>(
    'instructors/delete',
    async (id, thunkAPI) => {
        const { extra, dispatch, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.delete<Instructor[]>(`/instructor/${id}`);
            return response.data
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue('Failed to delete instructor');
        } finally {
            dispatch(fetchAllUsers())
        }
    }
);
