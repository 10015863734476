import { configureStore } from '@reduxjs/toolkit'
import { counterReducer } from '../../../../entities/Counter/'
import { UserReducer } from '../../../../entities/User'
import $api from '../../../../shared/api/api'
import { StateSchema } from './StateSchema'
import { AuthByUsernameReducer } from '../../../../features/AuthByUsername/model/slices/AuthByUsernameSlice'
import { StudentsReducer } from '../../../../features/Students/model/slices/StudentsSlice'
import { UsersReducer } from '../../../../features/Users/model/slices/UsersSlice'
import { SchoolsReducer } from '../../../../features/Schools/model/slices/SchoolsSlice'
import { InstructorsReducer } from '../../../../features/Instructors/model/slices/InstructorsSlice'
import { AvailabilityReducer } from '../../../../features/Availability/model/slices/AvailabilitySlice'
import { AppointmentsReducer } from '../../../../features/Appointments/model/slices/AppointmentsSlice'
import { WorkdayReducer } from '../../../../features/Workday/model/slices/WorkdaySlice'

export function createReduxStore(initialState?: StateSchema) {
    return configureStore({
        reducer: {
            counter: counterReducer,
            user: UserReducer,
            auth: AuthByUsernameReducer,
            students: StudentsReducer,
            users: UsersReducer,
            schools: SchoolsReducer,
            instructors: InstructorsReducer,
            availability: AvailabilityReducer,
            appointments: AppointmentsReducer,
            workday: WorkdayReducer

        },
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: {
                        api: $api
                    }
                }
            })
    })
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch']
