import { PhoneFilled } from '@ant-design/icons'
import { Card, Tag } from 'antd'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { HStack } from '../../../../shared/ui/Stack'
import { UserAvatarCircle } from '../../../../shared/ui/UserAvatarCircle/UserAvatarCircle'
import { Instructor } from '../../model/types/InstructorsSchema'
import cls from './InstructorCard.module.scss'

interface Props {
    handleNavigate: (instructorId: number) => void
    instructor: Instructor | null
    className?: string
    hoverable?: boolean
}
export const InstructorCard = (props: Props) => {
    const { handleNavigate, instructor, className, hoverable = true } = props

    if (!instructor) {
        return null
    }
    return (
        <Card
            styles={{ body: { padding: '10px' } }}
            rootClassName={classNames(cls.instructorCard, {}, [className])}
            hoverable={hoverable}
            onClick={() => handleNavigate(instructor.id)}
        >
            <Card.Meta
                avatar={<UserAvatarCircle url={instructor.user.photo} />}
                title={`${instructor.user.firstName} ${instructor.user.lastName}`}
                description={
                    <HStack gap="8">
                        <HStack gap="4">
                            <PhoneFilled />
                            {instructor.user.phone}
                        </HStack>
                        <Tag color="green">Инструктор</Tag>
                    </HStack>
                }
            />
        </Card>
    )
}
