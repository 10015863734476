import { Avatar } from 'antd-mobile'
import { defaultImageUrl } from '../../api/api'

interface Props {
    url?: string | undefined | null
}
export const UserAvatarCircle = ({ url }: Props) => {
    if (!url) {
        return null
    }
    return (
        <Avatar
            src={`${defaultImageUrl}/${url}`}
            style={{ borderRadius: '50%' }}
        />
    )
}
