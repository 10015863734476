import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInstructor, deleteInstructor, fetchAllInstructors, fetchAllInstructorsBySchoolId, fetchInstructorById, fetchInstructorWorkdaysFromToday } from '../services';
import { InstructorsSchema } from '../types/InstructorsSchema';
import { InstructorWorkdaysType } from '../types/instructorWorkdaysType';

const initialState: InstructorsSchema = {
    instructors: [],
    instructor: null,
    isLoading: false,
    error: null,

};

export const InstructorsSlice = createSlice({
    name: 'Instructors',
    initialState,
    reducers: {
        setUpdatedWorkdaysWebSocket: (state, action: PayloadAction<InstructorWorkdaysType[]>) => {
            state.instructorWorkdays = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllInstructors.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllInstructors.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructors = action.payload;
            })
            .addCase(fetchAllInstructors.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchAllInstructorsBySchoolId.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllInstructorsBySchoolId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructors = action.payload;
            })
            .addCase(fetchAllInstructorsBySchoolId.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteInstructor.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteInstructor.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructors = action.payload;
            })
            .addCase(deleteInstructor.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(createInstructor.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createInstructor.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructors.push(action.payload);
            })
            .addCase(createInstructor.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchInstructorWorkdaysFromToday.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.instructorWorkdays = undefined
            })
            .addCase(fetchInstructorWorkdaysFromToday.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructorWorkdays = action.payload
            })
            .addCase(fetchInstructorWorkdaysFromToday.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchInstructorById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.instructorWorkdays = undefined
            })
            .addCase(fetchInstructorById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.instructor = action.payload
            })
            .addCase(fetchInstructorById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
    },
});

export const { actions: InstructorsActions } = InstructorsSlice;
export const { reducer: InstructorsReducer } = InstructorsSlice;