import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "../../../../app/providers/StoreProvider";
import { handleAsyncError } from "../../../../shared/lib/helpers/handleAsyncError";
import { Appointment, AppointmentStatusesEnum } from "../types/AppointmentsSchema";

export const fetchAppointmentsInstructor = createAsyncThunk<
    Appointment[],
    AppointmentStatusesEnum,
    ThunkConfig<string>
>(
    'appointments/fetchAppointmentsInstructor',
    async (status, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Appointment[]>('/appointments/my-appointments/instructor', {
                params: {
                    status: status
                }
            });
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch appointments');
        }
    }
);