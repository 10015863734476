import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkdaySchema, Workday } from '../types/WorkdaySchema';
import { createWorkday, deleteWorkday, fetchAllWorkdays, fetchWorkDayDetailsById, updateWorkday } from '../services';

const initialState: WorkdaySchema = {
    workdays: [],
    isLoading: false,
    error: undefined,
};

export const workdaySlice = createSlice({
    name: 'workday',
    initialState,
    reducers: {
        setWorkdayDetailFromWebSocket: (state, action: PayloadAction<Workday>) => {
            const currentWorkdayDate = state.workdayDetails?.date
            const incomeWorkdayDate = action.payload.date

            if (currentWorkdayDate === incomeWorkdayDate) {
                state.workdayDetails = action.payload
            }

        },

    },
    extraReducers: (builder) => {
        builder
            // Create Workday
            .addCase(createWorkday.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(createWorkday.fulfilled, (state, action: PayloadAction<Workday>) => {
                state.isLoading = false;
                state.workdays.push(action.payload);
            })
            .addCase(createWorkday.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Fetch All Workdays
            .addCase(fetchAllWorkdays.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(fetchAllWorkdays.fulfilled, (state, action: PayloadAction<Workday[]>) => {
                state.isLoading = false;
                state.workdays = action.payload;
            })
            .addCase(fetchAllWorkdays.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Fetch workday details
            .addCase(fetchWorkDayDetailsById.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(fetchWorkDayDetailsById.fulfilled, (state, action: PayloadAction<Workday>) => {
                state.isLoading = false;
                state.workdayDetails = action.payload;
            })
            .addCase(fetchWorkDayDetailsById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Update Workday
            .addCase(updateWorkday.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(updateWorkday.fulfilled, (state, action: PayloadAction<Workday>) => {
                state.isLoading = false;
                const index = state.workdays.findIndex((wd) => wd.id === action.payload.id);
                if (index !== -1) {
                    state.workdays[index] = action.payload;
                }
            })
            .addCase(updateWorkday.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Delete Workday
            .addCase(deleteWorkday.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(deleteWorkday.fulfilled, (state, action) => {
                state.isLoading = false;
                state.workdays = state.workdays.filter((wd) => wd.id !== action.meta.arg);
            })
            .addCase(deleteWorkday.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});

export const { reducer: WorkdayReducer, actions: WorkdayActions } = workdaySlice;
