
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { UserActions } from '../../../../entities/User';
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage';
import { UserRoles } from '../../../../shared/lib/globalTypes/tenderSearch';
import { AuthResponseData } from '../types/AuthByUsernameSchema';


interface AuthProps {
    login: string,
    password: string,
    callback: (role: UserRoles) => void
}




export const authenticate = createAsyncThunk<AuthResponseData, AuthProps, ThunkConfig<string>>(
    'AuthByusername/authenticate',
    async ({ login, password, callback }, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<AuthResponseData>('/auth/login', {
                login,
                password
            });

            if (!response.data) {
                throw new Error();
            }
            const { token, refreshToken: newRefreshToken, user } = response.data;
            localStorage.setItem(USER_TOKEN_KEY, token);
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, newRefreshToken);
            dispatch(UserActions.setUserData(user))
            dispatch(UserActions.setConnectedToSchool(Boolean(response.data.connectedToSchool)))
            callback(response.data.user.role)
            return response.data;
        } catch (e: any) {

            return rejectWithValue('Неправильный логин или пароль');
        } finally {
            // Завершение инициализации авторизации
            dispatch(UserActions.initAuth());
        }
    },
)




export const refreshToken = createAsyncThunk<AuthResponseData, void, ThunkConfig<string>>(
    'auth/refreshToken',
    async (_, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {

            const refreshToken = localStorage.getItem(USER_REFRESH_TOKEN_KEY);
            const response = await extra.api.post<AuthResponseData>('/auth/refresh-token', {
                refreshToken
            }
            );
            if (!response.data) {
                throw new Error();
            }

            const { token, refreshToken: newRefreshToken, user } = response.data;
            // Обновляем токены
            localStorage.setItem(USER_TOKEN_KEY, token);
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, newRefreshToken);
            dispatch(UserActions.setConnectedToSchool(Boolean(response.data.connectedToSchool)))
            dispatch(UserActions.setUserData(user))
            dispatch(UserActions.initAuth())


            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        } finally {
            // Завершение инициализации авторизации
            dispatch(UserActions.initAuth());
        }
    },
)

