import { Instructor } from "../../../Instructors/model/types/InstructorsSchema";
import { Student } from "../../../Students/model/types/StudentsSchema";

export interface Appointment {
    id: number;
    studentId: number;
    instructorId: number;
    availabilityId: number;
    date: Date;
    startTime: string;
    endTime: string;
    status: AppointmentStatusesEnum;
    notes: string;
    studentRating: null;
    createdAt: Date;
    instructor: Instructor
    updatedAt: Date;
    student: Student;
}

export interface AppointmentsSchema {
    isLoading: boolean;
    error?: string;
    appointments: Appointment[];
    studentAppointments?: Appointment[]
}


export enum AppointmentStatusesEnum {
    BOOKED = "BOOKED",
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED",
    NO_SHOW = "NO_SHOW",
    RESCHEDULED = "RESCHEDULED",
    CANCELLED_BY_INSTRUCTOR = "CANCELLED_BY_INSTRUCTOR",
    CANCELLED_BY_STUDENT = "CANCELLED_BY_STUDENT",
    MISSED = "MISSED",
}