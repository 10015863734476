import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';
import { UpdateUserDto, User } from '../types/UsersSchema';

// Создание пользователя
export const createUser = createAsyncThunk<
    User,
    { userData: FormData; callBack: () => void },
    ThunkConfig<string>
>(
    'users/createUser',
    async ({ userData, callBack }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.post<User>('/users', userData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            callBack();
            return response.data;
        } catch (e: any) {
            handleAsyncError(e);
            return rejectWithValue(
                e.response?.data?.message || 'Failed to create user'
            );
        }
    }
);


// Получение всех пользователей
export const fetchAllUsers = createAsyncThunk<User[], void, ThunkConfig<string>>(
    'users/fetchAllUsers',
    async (_, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.get<User[]>('/users');
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Failed to fetch users');
        }
    }
);

export const fetchAllUsersPreferedMySchool = createAsyncThunk<User[], void, ThunkConfig<string>>(
    'users/fetchAllUsersPreferedMySchool',
    async (_, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.get<User[]>('/users/prefered', {
                params: { role: "STUDENT" }
            });
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Failed to fetch users');
        }
    }
);

// Получение одного пользователя
export const fetchUserById = createAsyncThunk<User, number, ThunkConfig<string>>(
    'users/fetchUserById',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.get<User>(`/users/${id}`);
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Failed to fetch user');
        }
    }
);

// Обновление пользователя
export const updateUser = createAsyncThunk<User, { id: number; data: UpdateUserDto }, ThunkConfig<string>>(
    'users/updateUser',
    async ({ id, data }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.patch<User>(`/users/${id}`, data);
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Failed to update user');
        }
    }
);

// Удаление пользователя
export const deleteUser = createAsyncThunk<void, number, ThunkConfig<string>>(
    'users/deleteUser',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            await extra.api.delete(`/users/${id}`);
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Failed to delete user');
        }
    }
);

