import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthByUsernameSchema } from '../types/AuthByUsernameSchema';
import { authenticate } from '../services';

const initialState: AuthByUsernameSchema = {

};

export const AuthByUsernameSlice = createSlice({
    name: 'AuthByUsername',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(authenticate.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(authenticate.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(authenticate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: AuthByUsernameActions } = AuthByUsernameSlice;
export const { reducer: AuthByUsernameReducer } = AuthByUsernameSlice;