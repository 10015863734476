import { StateSchema } from "../../../../app/providers/StoreProvider";

export const getUserData = (state: StateSchema) => state.user
export const getUserSchoolId = (state: StateSchema) => state.user.user?.schoolId
export const getUserId = (state: StateSchema) => state.user.user?.id

export const getUserIsAuth = (state: StateSchema) => state.user.isAuth
export const getUserInited = (state: StateSchema) => state.user._inited
export const getUserInitLoading = (state: StateSchema) => state.user.isLoading
export const getUserRole = (state: StateSchema) => state.user.user?.role 
