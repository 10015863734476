import { Appointment } from "../../../Appointments/model/types/AppointmentsSchema";

export interface AvailabilitySchema {
    availabilities: Availability[];
    isLoading: boolean;
    error: string | null;
}

export interface Availability {
    id: number;
    instructorId: number;
    dayOfWeek: number; // 0 - воскресенье, 6 - суббота
    date: string
    startTime: string; // Формат "HH:mm"
    endTime: string; // Формат "HH:mm"
    createdAt: string;
    updatedAt: string;
    status: AvailabilityStatusEnum
    appointment: Appointment
    instructor: {
        id: number;
        userId: number;
        user: {
            id: number;
            firstName: string;
            lastName: string;
            role: string; // Например, "INSTRUCTOR"
        };
    };
}

export interface CreateAvailabilityDto {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
}

export interface UpdateAvailabilityDto {
    dayOfWeek?: number;
    startTime?: string;
    endTime?: string;
}

export enum AvailabilityStatusEnum {
    AVAILABLE = "AVAILABLE",
    BOOKED = "BOOKED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    MISSED = "MISSED",
}
