import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    createAppointment,
    deleteAppointment,
    fetchAppointments,
    updateAppointment
} from '../services';
import { fetchAppointmentsInstructor } from '../services/forInstructor';
import { fetchAppointmentsStudent } from '../services/forStudent';
import { Appointment, AppointmentsSchema } from '../types/AppointmentsSchema';

const initialState: AppointmentsSchema = {
    isLoading: false,
    appointments: [],
};

const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        setUpdatedAppointmentsWebSocket: (state, action: PayloadAction<Appointment[]>) => {
            state.studentAppointments = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch All
            .addCase(fetchAppointments.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(fetchAppointments.fulfilled, (state, action: PayloadAction<Appointment[]>) => {
                state.isLoading = false;
                state.appointments = action.payload;
            })
            .addCase(fetchAppointments.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Fetch All appointments (student)
            .addCase(fetchAppointmentsStudent.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(fetchAppointmentsStudent.fulfilled, (state, action: PayloadAction<Appointment[]>) => {
                state.isLoading = false;
                state.studentAppointments = action.payload;
            })
            .addCase(fetchAppointmentsStudent.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Fetch All appointments (instructor)
            .addCase(fetchAppointmentsInstructor.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(fetchAppointmentsInstructor.fulfilled, (state, action: PayloadAction<Appointment[]>) => {
                state.isLoading = false;
                state.studentAppointments = action.payload;
            })
            .addCase(fetchAppointmentsInstructor.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Create
            .addCase(createAppointment.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(createAppointment.fulfilled, (state, action: PayloadAction<Appointment>) => {
                state.isLoading = false;
                state.appointments.push(action.payload);
            })
            .addCase(createAppointment.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Update
            .addCase(updateAppointment.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(updateAppointment.fulfilled, (state, action: PayloadAction<Appointment>) => {
                state.isLoading = false;
                const index = state.appointments.findIndex((a) => a.id === action.payload.id);
                if (index >= 0) {
                    state.appointments[index] = action.payload;
                }
            })
            .addCase(updateAppointment.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Delete
            .addCase(deleteAppointment.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(deleteAppointment.fulfilled, (state, action: PayloadAction<number>) => {
                state.isLoading = false;
                state.appointments = state.appointments.filter((a) => a.id !== action.payload);
            })
            .addCase(deleteAppointment.rejected, (state, action: PayloadAction<string | undefined>) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: AppointmentsActions } = appointmentsSlice;
export const { reducer: AppointmentsReducer } = appointmentsSlice;
