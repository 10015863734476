import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';
import { Appointment } from '../types/AppointmentsSchema';

interface CreateAppointmentProps {
    availabilityId: number
    notes: string
    callback: () => any

}
export const createAppointment = createAsyncThunk<
    Appointment,
    CreateAppointmentProps,
    ThunkConfig<string>
>(
    'appointments/create',
    async ({ availabilityId, notes, callback }, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.post<Appointment>('/appointments', { availabilityId, notes });
            callback()
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to create appointment');
        }
    }
);

export const fetchAppointments = createAsyncThunk<
    Appointment[],
    void,
    ThunkConfig<string>
>(
    'appointments/fetchAll',
    async (_, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Appointment[]>('/appointments');
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch appointments');
        }
    }
);

export const fetchAppointmentById = createAsyncThunk<
    Appointment,
    number,
    ThunkConfig<string>
>(
    'appointments/fetchById',
    async (id, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Appointment>(`/appointments/${id}`);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch appointment');
        }
    }
);

export const updateAppointment = createAsyncThunk<
    Appointment,
    { id: number; data: Partial<Appointment> },
    ThunkConfig<string>
>(
    'appointments/update',
    async ({ id, data }, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.patch<Appointment>(`/appointments/${id}`, data);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to update appointment');
        }
    }
);

interface DeleteAppointmentProps {
    appointmentId: number
    callback: () => void
}

export const deleteAppointment = createAsyncThunk<number, DeleteAppointmentProps, ThunkConfig<string>
>(
    'appointments/delete',
    async ({ appointmentId, callback }, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            await extra.api.delete(`/appointments/${appointmentId}`);
            callback()
            return appointmentId;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to delete appointment');
        }
    }
);
