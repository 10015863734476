import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import { ru } from 'date-fns/locale'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import moment from 'moment'
import { memo, useMemo, useState } from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useSelector } from 'react-redux'
import { getUserData } from '../../../../entities/User'
import { HStack } from '../../../../shared/ui/Stack'
import { CreateAppointmentButton } from '../../../Appointments/ui/CreateAppointmentButton/CreateAppointmentButton'
import { AvailabilityStatusEnum } from '../../../Availability/model/types/AvailabilitySchema'
import { InstructorWorkdaysType } from '../../../Instructors/model/types/instructorWorkdaysType'
import { EventType, EventWithButtonType } from '../../model/types/CalendarTypes'
import cls from './StudentworkdaysCalendar.module.scss'
import { useSwipeable } from 'react-swipeable'

interface StudentWorkdaysCalendarProps {
    workdays?: InstructorWorkdaysType[]
    handleFetchNewData: () => void
}

const locales = {
    ru: ru
}

const StudentWorkdaysCalendar = memo((props: StudentWorkdaysCalendarProps) => {
    const { workdays, handleFetchNewData } = props
    const { user } = useSelector(getUserData)

    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
    })

    const events = useMemo(() => {
        const allEvents: EventType[] = []
        if (!workdays) return allEvents

        workdays.forEach((workday) => {
            workday.availabilities.forEach((availability) => {
                const startDateTime = moment(workday.date)
                    .set('hour', parseInt(availability.startTime.split(':')[0]))
                    .set(
                        'minute',
                        parseInt(availability.startTime.split(':')[1])
                    )
                    .toDate()

                const endDateTime = moment(workday.date)
                    .set('hour', parseInt(availability.endTime.split(':')[0]))
                    .set('minute', parseInt(availability.endTime.split(':')[1]))
                    .toDate()

                allEvents.push({
                    id: availability.id,
                    title: `${workday.instructor.user.firstName}`,
                    start: startDateTime,
                    end: endDateTime,
                    availability,
                    instructor: workday.instructor,
                    instructorId: workday.instructorId,
                    workday
                })
            })
        })
        return allEvents
    }, [workdays])

    const EventWithButton = ({ event }: EventWithButtonType) => {
        return <CreateAppointmentButton event={event} />
    }

    const [currentDate, setCurrentDate] = useState(new Date())

    const handleSwipeLeft = () => {
        const nextDay = new Date(currentDate)
        nextDay.setDate(currentDate.getDate() + 1)
        setCurrentDate(nextDay)
        handleFetchNewData()
    }

    const handleSwipeRight = () => {
        const prevDay = new Date(currentDate)
        prevDay.setDate(currentDate.getDate() - 1)
        setCurrentDate(prevDay)
        handleFetchNewData()
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight
    })

    const CustomToolbar = ({ label, onNavigate }: any) => {
        return (
            <HStack justify="between" className={cls.toolbar}>
                <HStack>
                    <button
                        className={cls.button}
                        onClick={() => onNavigate('PREV')}
                    >
                        <CaretLeftOutlined />
                    </button>
                    <button
                        className={cls.button}
                        onClick={() => onNavigate('TODAY')}
                    >
                        Сегодня
                    </button>
                    <button
                        className={cls.button}
                        onClick={() => onNavigate('NEXT')}
                    >
                        <CaretRightOutlined />
                    </button>
                </HStack>
                <div>
                    <span className={cls.day_title}>{label}</span>
                </div>
            </HStack>
        )
    }

    return (
        <div {...swipeHandlers} style={{ height: '90vh', zIndex: 9999 }}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                views={[Views.DAY]}
                date={currentDate}
                defaultDate={currentDate}
                defaultView={Views.DAY}
                onNavigate={(date) => setCurrentDate(date)}
                selectable
                culture="ru"
                min={new Date(2024, 0, 1, 8, 0)} // начало времени 08:00
                max={new Date(2024, 0, 1, 21, 0)} // конец времени 21:00
                messages={{
                    next: 'след',
                    previous: 'пред',
                    today: 'сегодня',
                    month: 'Месяц',
                    week: 'Неделя',
                    day: 'День',
                    agenda: 'Повестка',
                    noEventsInRange: 'Событий нет',
                    showMore: () => 'откр.'
                }}
                components={{
                    event: EventWithButton,
                    toolbar: CustomToolbar
                }}
                eventPropGetter={(event) => {
                    const isAppointmentOwnerUser =
                        event.availability.appointment?.student.userId ===
                        user?.id

                    const available =
                        event.availability.status ===
                        AvailabilityStatusEnum.AVAILABLE
                    const booked =
                        event.availability.status ===
                        AvailabilityStatusEnum.BOOKED

                    const backgroundColor = isAppointmentOwnerUser
                        ? '#61AFFE'
                        : available
                        ? '#F6FFED'
                        : booked
                        ? 'orange'
                        : '#F0F2F5'
                    return {
                        style: {
                            backgroundColor,
                            color: isAppointmentOwnerUser ? 'white' : 'black',
                            border: '1px solid #b7eb8f'
                        }
                    }
                }}
            />
        </div>
    )
})

export default StudentWorkdaysCalendar
