import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import $api from '../../../../shared/api/api';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';
import { fetchAllUsers } from '../../../Users/model/services';
import { CreateStudentDto, Student } from '../types/StudentsSchema';

const STUDENTS_API_URL = '/student';

// Создание студента


export const createStudent = createAsyncThunk<Student[], CreateStudentDto, ThunkConfig<string>>(
    'students/createStudent',
    async (data, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<Student[]>(STUDENTS_API_URL, data);
            if (!response.data) {
                throw new Error();
            }
            dispatch(fetchAllUsers())
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue('error');
        }
    },
)

// Получение всех студентов
export const fetchAllStudents = createAsyncThunk<Student[], void, { rejectValue: string }>(
    'students/fetchAllStudents',
    async (_, { rejectWithValue }) => {
        try {
            const response = await $api.get(STUDENTS_API_URL);
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Ошибка получения списка студентов');
        }

    }
);

// Получение одного студента
export const fetchStudentById = createAsyncThunk<Student, number, { rejectValue: string }>(
    'students/fetchStudentById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await $api.get(`${STUDENTS_API_URL}/${id}`);
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Ошибка получения данных студента');
        }
    }
);

// Обновление данных студента
export const updateStudent = createAsyncThunk<void, { id: number; data: Partial<CreateStudentDto> }, { rejectValue: string }>(
    'students/updateStudent',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            await $api.patch(`${STUDENTS_API_URL}/${id}`, data);
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue(e.response?.data?.message || 'Ошибка обновления данных студента');
        }
    }
);

// Удаление студента
export const deleteStudent = createAsyncThunk<Student[], number, ThunkConfig<string>>(
    'students/deleteStudent',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.delete<Student[]>(`${STUDENTS_API_URL}/${id}`);
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            handleAsyncError(e)
            return rejectWithValue('error');
        }
    },
)