import { memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    getInstructorDetails,
    getInstructorWorkDays,
    InstructorCard
} from '../../../../../features/Instructors'
import {
    fetchInstructorById,
    fetchInstructorWorkdaysFromToday
} from '../../../../../features/Instructors/model/services'
import StudentWorkdaysCalendar from '../../../../../features/Students/ui/StudentworkdaysCalendar/StudentworkdaysCalendar'
import { classNames } from '../../../../../shared/lib/classNames/classNames'
import { useAppDispatch } from '../../../../../shared/lib/hooks/useAppDispatch/useAppDispatch'
import cls from './InstructorDetailPage.module.scss'
import { useSocketContext } from '../../../../../app/providers/WebSocketContext/WebSocketContext'

interface InstructorDetailPageProps {
    className?: string
}

const InstructorDetailPage = memo((props: InstructorDetailPageProps) => {
    const { className } = props
    const dispatch = useAppDispatch()
    const params = useParams()
    const workdaysData = useSelector(getInstructorWorkDays)
    const instructorDetails = useSelector(getInstructorDetails)
    const { socket } = useSocketContext()

    const workdays = useMemo(() => {
        return workdaysData
    }, [workdaysData])

    const handleFetchNewData = () => {
        if (params.instructorId)
            dispatch(
                fetchInstructorWorkdaysFromToday(Number(params.instructorId))
            )
    }
    useEffect(() => {
        if (params.instructorId) handleFetchNewData()
        dispatch(fetchInstructorById(Number(params.instructorId)))
        //eslint-disable-next-line
    }, [params, dispatch])

    useEffect(() => {
        if (socket && instructorDetails?.userId) {
            socket.emit('joinRoom', instructorDetails?.userId)
        }
        return () => {
            if (socket && instructorDetails?.userId) {
                console.log('leave')
                socket.emit('leaveRoom', instructorDetails?.userId)
            }
        }
    }, [socket, instructorDetails?.userId])

    return (
        <div className={classNames(cls.InstructorDetailPage, {}, [className])}>
            <InstructorCard
                hoverable={false}
                className={cls.instructorCard}
                handleNavigate={(instructorId: number) => console.log()}
                instructor={instructorDetails}
            />
            <StudentWorkdaysCalendar
                workdays={workdays}
                handleFetchNewData={handleFetchNewData}
            />
        </div>
    )
})

export default InstructorDetailPage
