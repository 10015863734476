import { createAsyncThunk } from '@reduxjs/toolkit';
import { Workday, CreateWorkdayDto, UpdateWorkdayDto } from '../types/WorkdaySchema';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { handleAsyncError } from '../../../../shared/lib/helpers/handleAsyncError';

// Создание рабочего дня
export const createWorkday = createAsyncThunk<Workday, CreateWorkdayDto, ThunkConfig<string>>(
    'workday/createWorkday',
    async ({ date, callback }, thunkAPI) => {
        const { extra, dispatch, rejectWithValue } = thunkAPI;
        try {
            console.log(date)
            const response = await extra.api.post<Workday>('/workday', { date });
            dispatch(fetchAllWorkdays())
            callback()
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to create workday');
        }
    }
);

// Получение всех рабочих дней для текущего инструктора
export const fetchWorkDayDetailsById = createAsyncThunk<Workday, number, ThunkConfig<string>>(
    'workday/fetchWorkDayDetailsById',
    async (workdayId, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Workday>(`/workday/${workdayId}`);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch workday');
        }
    }
);


// Получение всех рабочих дней для текущего инструктора
export const fetchAllWorkdays = createAsyncThunk<Workday[], void, ThunkConfig<string>>(
    'workday/fetchAllWorkdays',
    async (_, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.get<Workday[]>('/workday');
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch workdays');
        }
    }
);

// Обновление рабочего дня
export const updateWorkday = createAsyncThunk<Workday, { id: number; data: UpdateWorkdayDto }, ThunkConfig<string>>(
    'workday/updateWorkday',
    async ({ id, data }, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            const response = await extra.api.patch<Workday>(`/workday/${id}`, data);
            return response.data;
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to update workday');
        }
    }
);

// Удаление рабочего дня
export const deleteWorkday = createAsyncThunk<void, number, ThunkConfig<string>>(
    'workday/deleteWorkday',
    async (id, thunkAPI) => {
        const { extra, rejectWithValue } = thunkAPI;
        try {
            await extra.api.delete(`/workday/${id}`);
        } catch (error: any) {
            handleAsyncError(error)
            return rejectWithValue(error.response?.data?.message || 'Failed to delete workday');
        }
    }
);
